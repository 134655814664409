import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDistrct = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, typeName = '', tspTotal, mopTotal, dapTotal, total) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
        }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          {
            text: vm.$t('fertilizerReport.krishi_bhaban'),
            style: 'krishi',
            alignment: 'center'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]

        if (reportHeadData.projectName) {
          pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        if (typeName) {
          pdfContent.push({ text: typeName, style: 'report_type', alignment: 'center' })
        }
        pdfContent.push({ text: vm.$t('fertilizerReport.accountMayTons'), style: 'fertilizer', alignment: 'right', bold: true })
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
            { text: vm.$t('fertilizerReport.disTrict'), style: 'th', alignment: 'center' },
            { text: vm.$t('fertilizerReport.TSP'), style: 'th', alignment: 'right' },
            { text: vm.$t('fertilizerReport.MOP'), style: 'th', alignment: 'right' },
            { text: vm.$t('fertilizerReport.DAP'), style: 'th', alignment: 'right' },
            { text: vm.$t('fertilizerReport.total'), style: 'th', alignment: 'right' }
          ]
        ]
        data.forEach((info, index) => {
          allRows.push([
            { text: vm.$n(index + 1), alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.district_name_bn : info.district_name, alignment: 'center', style: 'td' },
            { text: vm.$n(info.tspTotal), alignment: 'right', style: 'td' },
            { text: vm.$n(info.mopTotal), alignment: 'right', style: 'td' },
            { text: vm.$n(info.dapTotal), alignment: 'right', style: 'td' },
            { text: vm.$n(info.total), alignment: 'right', style: 'td' }
          ])
        })
        allRows.push([
          { text: vm.$t('fertilizerReport.GrandTotalMTon'), alignment: 'right', style: 'th', colSpan: 2, bold: true },
          {},
          { text: vm.$n(tspTotal), alignment: 'right', style: 'th', bold: true },
          { text: vm.$n(mopTotal), alignment: 'right', style: 'th', bold: true },
          { text: vm.$n(dapTotal), alignment: 'right', style: 'th', bold: true },
          { text: vm.$n(total), alignment: 'right', style: 'th', bold: true }
        ])
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['10%', '25%', '15%', '15%', '15%', '20%'],
            body: allRows
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          report_type: {
            fontSize: 9,
            margin: [0, 2, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('Total initial stock of district based fertilizers')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDistrct
}
