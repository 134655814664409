<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('fertilizerReport.opening_balance_report') }}</h4>
        </template>
      <template v-slot:body>
        <b-overlay>
          <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
              <b-row>
                 <b-col lg="6" sm="12">
                  <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="fiscal_year_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('globalTrans.fiscalYear')}}<span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="search.fiscal_year_id"
                        :options="fiscalYearList"
                        id="fiscal_year_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                    </b-form-group>
                  </ValidationProvider>
                  </b-col>
                   <b-col lg="6" sm="12">
                  <ValidationProvider name="Region"  vid="region_id" rules="required">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="region_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.region') }}
                      </template>
                      <b-form-select
                        plain
                        v-model="search.region_id"
                        :options="regionList"
                        id="region_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                        </b-form-select>
                    </b-form-group>
                  </ValidationProvider>
                  </b-col>
              </b-row>
              <b-row class="mt-4 pl-3">
                  <b-col xs="12" sm="12" md="8" lg="8">
                    <ValidationProvider>
                      <b-form-group
                      class="row"
                      label-cols-sm="0"
                      label-for="amount"
                      slot-scope="{ valid, errors }"
                      >
                      <b-form-radio-group
                      v-model="search.type"
                      :options="options"
                      class="mb-3"
                      value-field="item"
                      text-field="name"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      </b-form-radio-group>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4">
                     <b-button type="submit" variant="primary float-right">{{ $t('globalTrans.search')}}</b-button>
                  </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </b-overlay>
      </template>
    </iq-card>
      <b-row v-show="showData">
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('fertilizerReport.opening_balance_report') }}</h4>
            </template>
            <template v-slot:headerAction>
              <b-button class="btn_add_new" @click="exportOpenings()">
                <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
              </b-button>
            </template>
            <template v-slot:body>
              <b-overlay :show="loadingState">
                <b-row v-show="!loading">
                  <b-col>
                    <list-report-head :base-url="seedFertilizerServiceBaseUrl" :uri="reportHeadingList" :org-id="search.org_id">
                      <slot v-if="search.type === 1">
                        {{ $t('fertilizerReport.initialStocksOfRegionBasedFertilizers')}}
                      </slot>
                      <slot v-else-if="search.type === 2">
                        {{ $t('fertilizerReport.primaryOfRegionBasedFertilizers')}}
                      </slot>
                      <slot v-else-if="search.type === 3">
                        {{ $t('fertilizerReport.totalInitialStockOfDistrictBasedFertilizers')}}
                      </slot>
                      <slot v-else-if="search.type === 4">
                        {{ $t('fertilizerReport.totalInitialStockOfFertilizerByCategory')}}
                      </slot>
                    </list-report-head>
                  </b-col>
                </b-row>
                <b-row>
                  <div class="col text-right">
                    <span class="text-right text-dark font-weight-bold"> {{ $t('fertilizerReport.accountMayTons') }}</span>
                  </div>
                </b-row>
                <b-row>
                  <b-col md="12" class="table-responsive">
                    <b-overlay v-if="totStock">
                      <b-table-simple bordered hover small caption-top responsive>
                        <b-thead>
                          <b-tr>
                            <b-td style="width:10%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-td>
                            <b-td style="width:10%" class="text-center">{{ $t('globalTrans.nong') }}</b-td>
                            <b-td style="width:20%" class="text-center">{{ $t('fertilizerReport.godown') }}</b-td>
                            <b-td style="width:10%" class="text-right" v-for="(item, index) in fertilizerNameList" :key="index">{{ $i18n.locale === 'en' ? item.text_en : item.text_bn }}</b-td>
                            <b-td style="width:10%" class="text-right">{{ $t('fertilizerReport.total') }}</b-td>
                            <b-td style="width:20%" class="text-center">{{ $t('fertilizerReport.name_of_district') }}</b-td>
                            <b-td style="width:10%" class="text-center">{{ $t('fertilizerReport.comments') }}</b-td>
                          </b-tr>
                        </b-thead>
                        <b-tbody  v-for="(info, index) in balanceData" :key="index">
                          <b-tr>
                            <b-td colspan="9" style="text-align:center">
                              <div>{{ ($i18n.locale === 'bn') ? info.office_name_bn : info.office_name }}</div>
                            </b-td>
                          </b-tr>
                          <template v-for="(goDownInfo, indx) in info.goDownInfo">
                            <b-tr :key="index + indx + 999" class="dfs">
                              <b-td class="text-center">{{ $n(goDownInfo.slNew) }}</b-td>
                              <b-td class="text-center">{{ $n(indx + 1) }}</b-td>
                              <b-td class="text-center">{{ ($i18n.locale === 'bn') ? goDownInfo.name_bn : goDownInfo.name }}</b-td>
                              <b-td style="text-align:right;">{{ $n(goDownInfo.tspTotal) }}</b-td>
                              <b-td style="text-align:right;">{{ $n(goDownInfo.mopTotal) }}</b-td>
                              <b-td style="text-align:right;">{{ $n(goDownInfo.dapTotal) }}</b-td>
                              <b-td style="text-align:right;">{{ $n(goDownInfo.total) }}</b-td>
                              <b-td class="text-center">{{ ($i18n.locale === 'bn') ? goDownInfo.district_name_bn : goDownInfo.district_name }}</b-td>
                              <b-td class="text-center"></b-td>
                            </b-tr>
                          </template>
                          <b-tr class="dfs">
                            <b-th colspan="3" style="text-align:right;">{{ ($i18n.locale === 'bn') ? info.office_name_bn : info.office_name }} {{ $t('fertilizerReport.total') }}</b-th>
                            <b-th style="text-align:right;">{{$n(info.tsp)}}</b-th>
                            <b-th style="text-align:right;">{{$n(info.mop)}}</b-th>
                            <b-th style="text-align:right;">{{$n(info.dap)}}</b-th>
                            <b-th style="text-align:right;">{{$n(info.total)}}</b-th>
                            <b-th style="text-align:right;"></b-th>
                            <b-th style="text-align:right;"></b-th>
                          </b-tr>
                        </b-tbody>
                        <b-tfoot>
                          <b-th style="width:20%;text-align:right" colspan="3">{{ $t('fertilizerReport.GrandTotalMTon') }} </b-th>
                          <b-th style="width:10%;text-align:right">{{ $n(tspTotal) }}</b-th>
                          <b-th style="width:10%;text-align:right">{{ $n(mopTotal) }}</b-th>
                          <b-th style="width:10%;text-align:right">{{ $n(dapTotal) }}</b-th>
                          <b-th style="width:10%;text-align:right">{{ $n(total) }}</b-th>
                          <b-th style="width:20%"></b-th>
                          <b-th style="width:20%"></b-th>
                        </b-tfoot>
                      </b-table-simple>
                    </b-overlay>
                    <b-overlay v-else-if="regionTable">
                      <b-table-simple bordered hover small caption-top responsive>
                        <b-thead>
                          <b-tr>
                            <b-td style="width:10%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-td>
                            <b-td style="width:20%" class="text-center">{{ $t('fertilizerReport.region') }}</b-td>
                            <b-td style="width:10%" class="text-right" v-for="(item, index) in fertilizerNameList" :key="index">{{ $i18n.locale === 'en' ? item.text_en : item.text_bn }}</b-td>
                            <b-td style="width:10%" class="text-right">{{ $t('fertilizerReport.total') }}</b-td>
                          </b-tr>
                        </b-thead>
                        <b-tbody  v-for="(info, index) in regionOpeningData" :key="index">
                          <b-td class="text-center">{{ $n(index+1) }}</b-td>
                          <b-td class="text-center">{{ ($i18n.locale === 'bn') ? info.office_name_bn : info.office_name }}</b-td>
                          <b-td  v-for="(fertilizers, indx1) in info.fertilizers" :key="indx1" style="width:10%;text-align:right">{{ $n(fertilizers.total) }}</b-td>
                          <b-td class="text-right">{{ info.total }}</b-td>
                        </b-tbody>
                        <b-tfoot>
                          <b-th style="width:10%;text-align:right" colspan="2">{{ $t('fertilizerReport.GrandTotalMTon') }} </b-th>
                          <b-th style="width:10%;text-align:right">{{ $n(tspTotal) }}</b-th>
                          <b-th style="width:10%;text-align:right">{{ $n(mopTotal) }}</b-th>
                          <b-th style="width:10%;text-align:right">{{ $n(dapTotal) }}</b-th>
                          <b-th style="width:10%;text-align:right">{{ $n(total) }}</b-th>
                        </b-tfoot>
                      </b-table-simple>
                    </b-overlay>
                     <b-overlay v-else-if="districtTable">
                      <b-table-simple bordered hover small caption-top responsive>
                        <b-thead>
                          <b-tr>
                            <b-td style="width:10%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-td>
                            <b-td style="width:20%" class="text-center">{{ $t('fertilizerReport.disTrict') }}</b-td>
                            <b-td style="width:10%" class="text-right" v-for="(item, index) in fertilizerNameList" :key="index">{{ $i18n.locale === 'en' ? item.text_en : item.text_bn }}</b-td>
                            <b-td style="width:10%" class="text-right">{{ $t('fertilizerReport.total') }}</b-td>
                          </b-tr>
                        </b-thead>
                        <b-tbody  v-for="(info, index) in distrctOpeningData" :key="index">
                          <b-td class="text-center">{{ $n(index+1) }}</b-td>
                          <b-td class="text-center">{{ ($i18n.locale === 'bn') ? info.district_name_bn : info.district_name }}</b-td>
                          <b-td style="width:10%;text-align:right"  v-for="(fertilizers, indx1) in info.fertilizers" :key="indx1">{{ $n(fertilizers.total) }}</b-td>
                          <b-td class="text-right">{{ $n(info.total) }}</b-td>
                        </b-tbody>
                        <b-tfoot>
                          <b-th style="width:10%;text-align:right" colspan="2">{{ $t('fertilizerReport.GrandTotalMTon') }} </b-th>
                          <b-th style="width:10%;text-align:right">{{ $n(tspTotal) }}</b-th>
                          <b-th style="width:10%;text-align:right">{{ $n(mopTotal) }}</b-th>
                          <b-th style="width:10%;text-align:right">{{ $n(dapTotal) }}</b-th>
                          <b-th style="width:10%;text-align:right">{{ $n(total) }}</b-th>
                        </b-tfoot>
                      </b-table-simple>
                    </b-overlay>
                    <b-overlay v-else-if="divisionTable">
                      <b-table-simple bordered hover small caption-top responsive>
                        <b-thead>
                          <b-tr>
                            <b-td style="width:10%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-td>
                            <b-td style="width:20%" class="text-center">{{ $t('fertilizerReport.division') }}</b-td>
                            <b-td style="width:10%" class="text-right" v-for="(item, index) in fertilizerNameList" :key="index">{{ $i18n.locale === 'en' ? item.text_en : item.text_bn }}</b-td>
                            <b-td style="width:10%" class="text-right">{{ $t('fertilizerReport.total') }}</b-td>
                          </b-tr>
                        </b-thead>
                        <b-tbody  v-for="(info, index) in divisionOpeningData" :key="index">
                          <b-td class="text-center">{{ $n(index+1) }}</b-td>
                          <b-td class="text-center">{{ ($i18n.locale === 'bn') ? info.division_name_bn : info.division_name }}</b-td>
                          <b-td style="width:10%;text-align:right" v-for="(fertilizers, indx1) in info.fertilizers" :key="indx1">{{ $n(fertilizers.total) }}</b-td>
                          <b-td class="text-right">{{ $n(info.total) }}</b-td>
                        </b-tbody>
                        <b-tfoot>
                          <b-th style="width:10%;text-align:right" colspan="2">{{ $t('fertilizerReport.GrandTotalMTon') }} </b-th>
                          <b-th style="width:10%;text-align:right">{{ $n(tspTotal) }}</b-th>
                          <b-th style="width:10%;text-align:right">{{ $n(mopTotal) }}</b-th>
                          <b-th style="width:10%;text-align:right">{{ $n(dapTotal) }}</b-th>
                          <b-th style="width:10%;text-align:right">{{ $n(total) }}</b-th>
                        </b-tfoot>
                      </b-table-simple>
                    </b-overlay>
                  </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, openingBalanceReport } from '../../api/routes'
import ListReportHead from '.././ListReportHead.vue'
import ExportPdf from './export-pdf_details'
import ExportPdfRegion from './region'
import ExportPdfDistrct from './distrct'
import ExportPdfDivision from './division'

export default {
  name: 'UiDataTable',
  components: {
    ListReportHead
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      showData: false,
      regionTable: false,
      divisionTable: false,
      districtTable: false,
      totStock: false,
      balanceData: [],
      regionOpeningData: [],
      distrctOpeningData: [],
      divisionOpeningData: [],
      check: [],
      tspTotal: 0,
      mopTotal: 0,
      dapTotal: 0,
      total: 0,
      search: {
        type: 1,
        org_id: 3,
        fiscal_year_id: 0,
        region_id: 0
      },
      region_name: '',
      region_name_bn: '',
      currentDate: '',
      organization: [],
      testId: 0,
      rows: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      farmerList: [],
      stockData: [],
      grandTotal: [],
      warehouseList: [],
      regionDistrictLists: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList,
      totalInfo: {
        totalSpace: 0,
        actualSpace: 0,
        freeSpace: 0,
        filledSpace: 0
      }
    }
  },
  computed: {
    options: function () {
        return [
          { item: 1, name: this.$t('fertilizerReport.details') },
          { item: 2, name: this.$t('fertilizerReport.region_total') },
          { item: 3, name: this.$t('fertilizerReport.district_total') },
          { item: 4, name: this.$t('fertilizerReport.division_total') }
        ]
     },
     fiscalYearList: function () {
        const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        const tempData = fiscalYears.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
      },
    regionList: function () {
       return this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.office_type_id === 72)
    },
    fertilizerNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    godownInfoList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    formTitle () {
       return this.$t('fertilizerReport.current_stock_report')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    // this.loadData()
  },
  mounted () {
    core.index()
  },
  methods: {
    searchClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length
      }
    },
    searchData () {
      this.showData = true
      if (this.search.type === 1) {
        this.districtTable = false
        this.divisionTable = false
        this.regionTable = false
        this.totStock = true
      }
      if (this.search.type === 2) {
        this.districtTable = false
        this.divisionTable = false
        this.totStock = false
        this.regionTable = true
      }
      if (this.search.type === 3) {
        this.totStock = false
        this.regionTable = false
        this.divisionTable = false
        this.districtTable = true
      }
      if (this.search.type === 4) {
        this.totStock = false
        this.regionTable = false
        this.districtTable = false
        this.divisionTable = true
      }
      this.loadData()
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      const params = Object.assign({}, this.search)
      await RestApi.getData(seedFertilizerServiceBaseUrl, openingBalanceReport, params).then(response => {
        if (response.success) {
          if (this.search.type === 1) {
            this.balanceData = this.getRelationalData(response.data)
          } else if (this.search.type === 2) {
            this.regionOpeningData = this.getRelationalDataUp(response.data)
            this.getTotalDataAdd(this.regionOpeningData)
          } else if (this.search.type === 3) {
            this.distrctOpeningData = this.getRelationalDataUp(response.data)
            this.getTotalDataAdd(this.distrctOpeningData)
          } else {
            this.divisionOpeningData = this.getRelationalDataUp(response.data)
            this.getTotalDataAdd(this.divisionOpeningData)
          }
        } else {
          this.balanceData = []
          this.regionOpeningData = []
          this.distrctOpeningData = []
          this.divisionOpeningData = []
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelationalDataUp (data) {
      const allDatas = []
      data.forEach((element, key) => {
        let grTotal = 0
        const fert = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList
        const ferts = fert.map(item => {
          const openingBalance = element.openingBalance.find(openingBalance => openingBalance.fertilizer_id === item.value)
          const openingBalanceAm = openingBalance !== undefined ? openingBalance.quantity : 0
          const loriChalan = element.loriChalan.find(loriChalan => loriChalan.fertilizer_id === item.value)
          const loriChalanAm = loriChalan !== undefined ? loriChalan.received_amount : 0

          const interGodownReceive = element.interGodownReceive.find(interGodownReceive => interGodownReceive.fertilizer_id === item.value)
          const interGodownReceiveAm = interGodownReceive !== undefined ? interGodownReceive.received_amount : 0

          const pointOfSale = element.pointOfSale.find(pointOfSale => pointOfSale.fertilizer_id === item.value)
          const pointOfSaleAm = pointOfSale !== undefined ? pointOfSale.quantity : 0

          const interGodownDelivery = element.interGodownDelivery.find(interGodownDelivery => interGodownDelivery.fertilizer_id === item.value)
          const interGodownDeliveryAm = interGodownDelivery !== undefined ? interGodownDelivery.amount_of_fertilizer : 0
          const total = (openingBalanceAm + loriChalanAm + interGodownReceiveAm) - (pointOfSaleAm + interGodownDeliveryAm)
          grTotal += total
          return Object.assign({}, item, {
            total: total
          })
        })
        element.fertilizers = ferts
        element.total = grTotal
        allDatas[key] = element
      })
      return data
    },
    getTotalDataAdd (data) {
        let tspTotal = 0
        let mopTotal = 0
        let dapTotal = 0
        data.forEach((element, key) => {
          const loriChalanTsp = element.fertilizers.find(fertilizer => fertilizer.value === 1)
          element.tspTotal = loriChalanTsp !== undefined ? loriChalanTsp.total : 0

          const loriChalanMop = element.fertilizers.find(fertilizer => fertilizer.value === 2)
          element.mopTotal = loriChalanMop !== undefined ? loriChalanMop.total : 0

          const loriChalanDap = element.fertilizers.find(fertilizer => fertilizer.value === 3)
          element.dapTotal = loriChalanDap !== undefined ? loriChalanDap.total : 0

          tspTotal += element.tspTotal
          mopTotal += element.mopTotal
          dapTotal += element.dapTotal
        })
        this.tspTotal = tspTotal
        this.mopTotal = mopTotal
        this.dapTotal = dapTotal
        this.total = this.tspTotal + this.mopTotal + this.dapTotal
    },
    exportOpenings () {
      const reportTypeTitle = ''
      if (this.search.type === 1) {
        const reportTitle = this.$t('fertilizerReport.initialStocksOfRegionBasedFertilizers')
        ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.balanceData, this, reportTypeTitle, this.tspTotal, this.mopTotal, this.dapTotal, this.total)
      } else if (this.search.type === 2) {
        const reportTitle = this.$t('fertilizerReport.primaryOfRegionBasedFertilizers')
        ExportPdfRegion.exportPdfRegion(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.regionOpeningData, this, reportTypeTitle, this.tspTotal, this.mopTotal, this.dapTotal, this.total)
      } else if (this.search.type === 3) {
        const reportTitle = this.$t('fertilizerReport.totalInitialStockOfDistrictBasedFertilizers')
        ExportPdfDistrct.exportPdfDistrct(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.distrctOpeningData, this, reportTypeTitle, this.tspTotal, this.mopTotal, this.dapTotal, this.total)
      } else if (this.search.type === 4) {
        const reportTitle = this.$t('fertilizerReport.totalInitialStockOfFertilizerByCategory')
        ExportPdfDivision.exportPdfDivision(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.divisionOpeningData, this, reportTypeTitle, this.tspTotal, this.mopTotal, this.dapTotal, this.total)
      }
    },
    getRelationalData (data) {
        let tspTotal = 0
        let mopTotal = 0
        let dapTotal = 0
        const districtList = this.$store.state.commonObj.districtList
        const allDatas = []
        let sl = 0
        data.forEach((element, key) => {
          let tsp = 0
          let mop = 0
          let dap = 0
          let total = 0
          element.goDownInfo.forEach((element2, key2) => {
            const districtObject = districtList.find(district => district.value === element2.district_id)
            element2.district_name = districtObject !== undefined ? districtObject.text_en : ''
            element2.district_name_bn = districtObject !== undefined ? districtObject.text_bn : ''

            const openingBalanceTsp = element2.openingBalance.find(openingBalance => openingBalance.fertilizer_id === 1)
            element2.openingBalanceTsp = openingBalanceTsp !== undefined ? openingBalanceTsp.quantity : 0

            const openingBalanceMop = element2.openingBalance.find(openingBalance => openingBalance.fertilizer_id === 2)
            element2.openingBalanceMop = openingBalanceMop !== undefined ? openingBalanceMop.quantity : 0

            const openingBalanceDAP = element2.openingBalance.find(openingBalance => openingBalance.fertilizer_id === 3)
            element2.openingBalanceDAP = openingBalanceDAP !== undefined ? openingBalanceDAP.quantity : 0

            const loriChalanTsp = element2.loriChalan.find(loriChalan => loriChalan.fertilizer_id === 1)
            element2.loriChalanTsp = loriChalanTsp !== undefined ? loriChalanTsp.received_amount : 0

            const loriChalanMop = element2.loriChalan.find(loriChalan => loriChalan.fertilizer_id === 2)
            element2.loriChalanMop = loriChalanMop !== undefined ? loriChalanMop.received_amount : 0

            const loriChalanDAP = element2.loriChalan.find(loriChalan => loriChalan.fertilizer_id === 3)
            element2.loriChalanDAP = loriChalanDAP !== undefined ? loriChalanDAP.received_amount : 0

            const interGodownReceiveTsp = element2.interGodownReceive.find(interGodownReceive => interGodownReceive.fertilizer_id === 1)
            element2.interGodownReceiveTsp = interGodownReceiveTsp !== undefined ? interGodownReceiveTsp.received_amount : 0

            const interGodownReceiveMop = element2.interGodownReceive.find(interGodownReceive => interGodownReceive.fertilizer_id === 2)
            element2.interGodownReceiveMop = interGodownReceiveMop !== undefined ? interGodownReceiveMop.received_amount : 0

            const interGodownReceiveDAP = element2.interGodownReceive.find(interGodownReceive => interGodownReceive.fertilizer_id === 3)
            element2.interGodownReceiveDAP = interGodownReceiveDAP !== undefined ? interGodownReceiveDAP.received_amount : 0

            const interGodownDeliveryTsp = element2.interGodownDelivery.find(interGodownDelivery => interGodownDelivery.fertilizer_id === 1)
            element2.interGodownDeliveryTsp = interGodownDeliveryTsp !== undefined ? interGodownDeliveryTsp.amount_of_fertilizer : 0

            const interGodownDeliveryMop = element2.interGodownDelivery.find(interGodownDelivery => interGodownDelivery.fertilizer_id === 2)
            element2.interGodownDeliveryMop = interGodownDeliveryMop !== undefined ? interGodownDeliveryMop.amount_of_fertilizer : 0

            const interGodownDeliveryDAP = element2.interGodownDelivery.find(interGodownDelivery => interGodownDelivery.fertilizer_id === 3)
            element2.interGodownDeliveryDAP = interGodownDeliveryDAP !== undefined ? interGodownDeliveryDAP.amount_of_fertilizer : 0

            const pointOfSaleTsp = element2.pointOfSale.find(pointOfSale => pointOfSale.fertilizer_id === 1)
            element2.pointOfSaleTsp = pointOfSaleTsp !== undefined ? pointOfSaleTsp.quantity : 0

            const pointOfSaleMop = element2.pointOfSale.find(pointOfSale => pointOfSale.fertilizer_id === 2)
            element2.pointOfSaleMop = pointOfSaleMop !== undefined ? pointOfSaleMop.quantity : 0

            const pointOfSaleDAP = element2.pointOfSale.find(pointOfSale => pointOfSale.fertilizer_id === 3)
            element2.pointOfSaleDAP = pointOfSaleDAP !== undefined ? pointOfSaleDAP.quantity : 0

            element2.tspTotal = (element2.openingBalanceTsp + element2.loriChalanTsp + element2.interGodownReceiveTsp) - (element2.pointOfSaleTsp + element2.interGodownDeliveryTsp)

            element2.mopTotal = (element2.openingBalanceMop + element2.loriChalanMop + element2.interGodownReceiveMop) - (element2.pointOfSaleMop + element2.interGodownDeliveryMop)
            element2.dapTotal = (element2.openingBalanceDAP + element2.loriChalanDAP + element2.interGodownReceiveDAP) - (element2.pointOfSaleDAP + element2.interGodownDeliveryDAP)

            element2.total = element2.tspTotal + element2.mopTotal + element2.dapTotal
            tsp += element2.tspTotal
            mop += element2.mopTotal
            dap += element2.dapTotal
            total += element2.total
            sl++
            element2.slNew = sl
          })
          element.tsp = tsp
          element.mop = mop
          element.dap = dap
          element.total = total
          allDatas[key] = element

          tspTotal += element.tsp
          mopTotal += element.mop
          dapTotal += element.dap
        })
        this.tspTotal = tspTotal
        this.mopTotal = mopTotal
        this.dapTotal = dapTotal
        this.total = this.tspTotal + this.mopTotal + this.dapTotal
        return allDatas
      }
    },
    getPdfData () {
        const keys = [
          { key: 'serial_no' },
          { key: this.$i18n.locale === 'en' ? 'name' : 'name_bn' },
          { key: this.$i18n.locale === 'en' ? 'district_name' : 'district_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'upazilla_name' : 'upazilla_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'warehouse_name' : 'warehouse_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'total_capacity' : 'total_capacity' },
          { key: this.$i18n.locale === 'en' ? 'actual_capacity' : 'actual_capacity' },
          { key: this.$i18n.locale === 'en' ? 'free_space' : 'free_space' },
          { key: this.$i18n.locale === 'en' ? 'filled_space' : 'filled_space' }
        ]
      var serial = 0
      const listData = this.balanceData.map(item => {
        serial += 1
        const rowData = keys.map((keyItem, index) => {
            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial) }
            }
          return { text: item[keyItem.key] }
        })
        return rowData
      })

      return listData
    }
  }
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
